<template>
  <SelectInput
      :name="defineName('target')"
      label="Target entity"
      :options="getMLFieldTypeKeysComp"
      @selected="handleSelectedTarget"
  />
  <RagFieldType
    :target="target"
    name="result"
    label="Result"
    @handleSelected="handleSelectedResult"
  />
</template>
<script>
import {mapGetters} from "vuex";
import RagFieldType from "@/components/rag-rule-manager/RagFieldType";

export default {
  name: "RagRuleResultFields",
  data: () => ({
    target: null,
    result: null,
  }),
  components: {
    RagFieldType,
  },
  computed: {
    ...mapGetters([
      "getProviders",
      "getMLFieldTypeKeys",
      "getMLFieldType",
    ]),
    getMLFieldTypeKeysComp() {
      let temp = [];
      let tempSource = this.getMLFieldTypeKeys();
      for(let i in tempSource) {
        temp.push({
          id: tempSource[i],
          text: tempSource[i]
        })
      }
      temp.sort((a, b) =>
          a.id > b.id
              ? 1
              : b.id > a.id
                  ? -1
                  : 0
      );
      return temp;
    },
  },
  methods: {
    handleSelectedTarget(value) {
      this.target = value;
      this.$emit('resultSelected', {
        type: 'target',
        value,
      });
    },
    handleSelectedResult(value) {
      this.result = value;
      this.$emit('resultSelected', {
        type: 'result',
        value,
      });
    },
  },
}
</script>
<style lang="scss"></style>