<template>
  <li class="nav-item">
    <a
      :class="[
        'nav-link link-menu-item text-left -no-underline',
        {
          active: isActive
        }
      ]"
      href="#"
      @click.prevent="handleClick"
      ><icon :data="iconSrc" width="26" height="26" dir="up" />
      {{ "&nbsp;&nbsp;" + getLinkName(linkName) }}</a
    >
  </li>
</template>
<script>
export default {
  name: "SubMenuLink",
  props: {
    linkName: {
      default: null
    },
    iconSrc: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: String,
      default: ""
    }
  },
  emits: ["navigate"],
  computed: {
    isActive() {
      return this.page === this.linkName;
    }
  },
  methods: {
    handleClick() {
      this.$emit("navigate", this.linkName);
    },
    getLinkName(linkName) {
      return linkName;
    }
  }
};
</script>
