<template>
  <div class="c-menu-sub">
    <ul class="nav flex-column c-menu-inner">
      <slot />
    </ul>
  </div>
</template>
<script>
export default {
  name: "SubMenu",
  data: () => ({})
};
</script>
<style lang="scss"></style>
