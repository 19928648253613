<template>
  <TextInput
      v-if="getMLFieldType(target) === 'Text' || ruleType === 'customer-age'"
      type="text"
      :name="defineName(name)"
      :label="label"
      @updated="handleSelectedResult"
  />
  <AmountInput
      v-else-if="getMLFieldType(target) === 'Number'"
      :name="defineName(name)"
      :label="label"
      @updated="handleSelectedResult"
  />
  <DatePicker
      v-else-if="getMLFieldType(target) === 'Date'"
      :name="defineName(name)"
      :label="label"
      @selected="handleSelectedResult"
  />
  <SelectInput
      v-else-if="getMLFieldType(target) === 'Enum'"
      :name="defineName(name)"
      :options="dropdownOptions"
      :label="label"
      @selected="handleSelectedResult"
  />
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "RageFieldType",
  props: {
    ind: {
      type: Number,
      default: null
    },
    ruleType: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
  },
  emits: ['handleSelected'],
  data: () => ({}),
  computed: {
    ...mapGetters([
      "getMLFieldType",
      "getMLFieldEnum"
    ]),
     dropdownOptions(){
      let options = this.getMLFieldEnum(this.target);
      console.log(this.name)
      console.log(options)
      return options?options:[
          {
            id: 'yes',
            text: 'Yes'
          },{
            id: 'no',
            text: 'No'
          },
      ]
    }
  },
  methods: {
    handleSelectedResult(value) {
      this.$emit('handleSelected', value);
      // this.$parent.handleSelectedResult(value);
    }
  },
}
</script>
<style lang="scss"></style>