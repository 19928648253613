<template>
  <SearchText
      @search-updated="handelSearch"
      @search-clear="handelSearchClear"
      label="Search..."
  />
  <div v-for="(r,i) in rulesComputed" :key="i">
    <RagCodeBlock
        :evaluation="r.rag_rule_condition"
        :target="r.target"
        :result="r.result"
        :created="r.created_at"
        :id="r.id"
        :review="true"
    />
  </div>
</template>
<script>

import {mapActions} from "vuex";
import RagCodeBlock from "@/components/rag-rule-manager/RagCodeBlock";
import SearchText from "@/components/search/SearchText";
import login from "@/views/Login";

export default {
  name: "RagRuleView",
  data: () => ({
    rules: [],
    searchedRules: null,
  }),
  components: {
    RagCodeBlock,
    SearchText,
  },
  computed: {
    rulesComputed() {
      return this.searchedRules || this.rules
    }
  },
  methods: {
    ...mapActions([
      'fetchRagRules',
      'searchRagRules',
    ]),
    async handelSearch(search) {
      if(search == "") {
        this.searchedRules = null;
        return;
      }
      let response = await this.searchRagRules({ search }).catch(err=>console.log(err));

      if(response.status == 200) {
        this.searchedRules = response.data.data;
      }
    },
    handelSearchClear() {
      this.searchedRules = null;
    },
    async fetchData() {
      let response = await this.fetchRagRules().catch(err => console.log(err));

      if(response.status == 200) {
        this.rules = response.data.data;
      }
    }
  },
  async beforeMount() {
    await this.fetchData();
  }
}
</script>
<style lang="scss"></style>