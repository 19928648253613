<template>
  <Form ref="conditionForm" class="w-100 text-left" @submit="saveForm">
    <span class="d-flex justify-content-end link-text" @click="handleClearForm">clear</span>
    <RagCodeBlock
        :evaluation="evaluation"
        :result="result"
        :target="target"
    />

    <div v-for="i in range" :key="i">
      <RagRuleFormFields
          :get-email-template-filter="getEmailTemplateFilter"
          :get-status-tp-values="getStatusTPValues"
          :ind="i"
          :information-required="informationRequired"
          :rule-type="ruleType"
          @ruleSelected="handleRuleSelected"
      />
    </div>

    <div v-if="evaluation.length > 0 && range < 2" class="pointer link-text mb-4" @click.prevent="handleAnd">AND</div>

    <RagRuleResultFields
        v-if="evaluation.length > 0"
        @resultSelected="handleResultSelected"
    />

    <button class="fabric-btn fabric-btn-submit">create</button>
  </Form>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import RagCodeBlock from "@/components/rag-rule-manager/RagCodeBlock";
import RagRuleFormFields from "@/components/rag-rule-manager/RagRuleFormFields";
import RagRuleResultFields from "@/components/rag-rule-manager/RagRuleResultFields";

export default {
  name: "RagRuleForm",
  data: () => ({
    showOptions: false,
    emailTemplate: false,
    ruleType: null,
    excludeList: [],
    range: 1,
    evaluation: [],
    target: null,
    result: null,
  }),
  components: {
    RagCodeBlock,
    RagRuleFormFields,
    RagRuleResultFields
  },
  computed: {
    ...mapGetters(["getStatusTPValues", "getEmailTemplates"]),
    informationRequired() {
      switch (this.eventType) {
        case "status-change":
          return [
            {
              id: "yes",
              text: "Yes"
            },
            {
              id: "no",
              text: "No"
            },
            {
              id: "previous_content",
              text: "Previous content"
            }
          ];
        default:
          return [
            {
              id: "no",
              text: "No"
            },
            {
              id: "previous_content",
              text: "Previous content"
            }
          ];
      }
    },
    ruleTypeOptions() {
      return [
        {
          id: "any-entity",
          text: "Any Entity"
        }, {
          id: "entity",
          text: "Entity"
        }, {
          id: "provider",
          text: "Provider"
        }, {
          id: "scheme",
          text: "Scheme"
        }, {
          id: "customer-age",
          text: "Customer Age"
        },
      ]
    },
    getEmailTemplateFilter() {
      return this.getEmailTemplates.filter(
          item => item.id !== this.emailTemplate
      );
    }
  },
  methods: {
    ...mapMutations([
      "setNotification"
    ]),
    ...mapActions([
      "addEmailSchedule",
      "fetchEmailSchedulerList",
      "addRagRule",
    ]),
    handleClearForm() {
      this.$refs.conditionForm.resetForm();
      this.range = 1;
      this.evaluation.splice(0);
      this.target = null;
      this.result = null;
    },
    handleRuleSelected(value) {
      let ind = (value.ind - 1);
      if (typeof this.evaluation[ind] === 'undefined') {
        this.evaluation[ind] = {};
      }
      Object.assign(this.evaluation[ind], value);
    },
    handleResultSelected({type, value}) {
      this[type] = value;
    },
    handleAnd() {
      this.range++;
    },
    async saveForm(params) {
      let tempStatements = [];
      for (let i = 0; i < this.range; i++) {
        let ind = i + 1;
        tempStatements.push({
          rule_type: params["rule_type_" + ind],
          entity_provider_scheme_customer: params["entity_provider_scheme_customer_" + ind] || params["rule_type_" + ind],
          operator: params["operator_" + ind],
          condition: params["condition_" + ind],
        });
      }

      let rules = {
        evaluation: tempStatements,
        target: this.target,
        result: this.result
      }

      let response = await this.addRagRule(rules).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error + "!"
        });
      });
      if (response) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Rag rule added successfully!"
        });
      }
    },
    templateSelected(value) {
      this.ruleType = value;
      this.showOptions = value !== "";
    },
    async fetchScheduleData() {
      let result = await this.fetchEmailSchedulerList().catch(err =>
          console.log(err)
      );
      if (!result) return;
      this.excludeList = result.data.data.map(i => i.email);
    }
  },
  async beforeMount() {
    await this.fetchScheduleData();
  }
};
</script>
<style lang="scss"></style>
