<template>
  <SelectInput
      :ref="'select_'+ind"
      :name="defineName('rule_type')"
      :label="'Rule type '+ ind"
      :with-blank-option="true"
      :options="ruleTypeOptions"
      @selected="ruleSelected"
  />
  <div v-if="showOptions">
    <p v-if="ruleType === 'any-entity' || ruleType === 'customer-age'">
      <input type="hidden" :name="defineName('entity_provider_scheme_customer')" :value="ruleType">
    </p>
    <SelectInput
      v-else-if="ruleType === 'entity'"
      :name="defineName('entity_provider_scheme_customer')"
      label="Entity name"
      placeholder=""
      :options="getMLFieldTypeKeysComp"
      @selected="handleSelectedEntity"
    />
    <SelectInput
      v-else-if="ruleType === 'provider' || ruleType === 'scheme'"
      :name="defineName('entity_provider_scheme_customer')"
      :label="ruleType === 'provider' ? 'Provider' : 'Scheme'"
      placeholder=""
      :options="getProvidersComp"
      @selected="handleSelectedProvider"
    />
    <SelectInput
      v-if="
        ruleType === 'entity' ||
        ruleType === 'any-entity' ||
        ruleType === 'customer-age'
      "
      :name="defineName('operator')"
      label="Operator"
      :options="operatorsComp"
      @selected="handleSelectedOperator"
    />
    <SelectInput
        v-if="ruleType === 'any-entity'"
        :name="defineName('entity_provider_scheme_customer')"
        label="Entity name"
        placeholder=""
        :options="getMLFieldTypeKeysComp"
        @selected="handleSelectedCondition"
    />
    <RagFieldType
        v-if="
          ruleType === 'entity' ||
          ruleType === 'customer-age'
        "
        :ind="ind"
        :rule-type="ruleType"
        :target="ePS"
        name="condition"
        label="Condition"
        @handleSelected="handleSelectedCondition"
    />
  </div>
  <hr>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import RagFieldType from "@/components/rag-rule-manager/RagFieldType";

export default {
  name: "RagRuleFormFields",
  props: {
    ind: {
      type: Number,
      default: null
    },
    getEmailTemplateFilter: {
      default: null
    },
    informationRequired: {
      default: null
    },
    getStatusTPValues: {
      default: null
    },
  },
  emits: ['ruleSelected'],
  data: () => ({
    eventType: null,
    ePS: null,
    operator: null,
    condition: null,
    target: null,
    result: null,
    ruleType: null,
    showOptions: null
  }),
  components: {
    RagFieldType,
  },
  watch : {
    ruleType(n) {
      if(n === 'provider' || n === 'scheme') {
        this.operator = null;
        this.condition = null;
      }
      if(n === 'any-entity' || n === 'customer-age') {
        this.emitUpdate(this.ePSComp);
      }
      this.ePS = null;
    },
    target() {
      this.result = null;
    }
  },
  computed: {
    ...mapGetters([
        "getProviders",
        "getMLFieldTypeKeys",
        "getMLFieldType",
        "getMlRuleType",
    ]),
    ruleTypeOptions() { // pass through filter to remove already selected
      let rules = [
        {
          id: "any-entity",
          text: "any entity"
        },{
          id: "entity",
          text: "entity"
        },{
          id: "provider",
          text: "provider"
        },{
          id: "scheme",
          text: "scheme"
        },{
          id: "customer-age",
          text: "customer age"
        },
      ];

      if(this.ind > 1) {
        return rules.filter(r=>r.id !==this.getMlRuleType);
      }

      return rules;
    },
    ePSComp() {
      if(!this.ruleType) return null;
      return this.ePS || this.deCamel(this.ruleType) || null;
    },
    getMLFieldTypeKeysComp() {
      let temp = [];
      let tempSource = this.getMLFieldTypeKeys();
      for(let i in tempSource) {
        temp.push({
          id: tempSource[i],
          text: tempSource[i]
        })
      }
      temp.sort((a, b) =>
          a.id > b.id
              ? 1
              : b.id > a.id
                  ? -1
                  : 0
      );
      return temp;
    },
    getProvidersComp() {
      return this.getProviders();
    },
    operatorsComp() {
      let op = this.operatorsArray();
      if(this.ruleType === 'any-entity')
        op=op.filter(i=>i.id!=='<'&&i.id!=='>');
      if(this.ruleType === 'entity' && [
          "adjustable_income",
          "power_of_attorney",
          "life_assurance",
          "safeguard_benefits",
          "policy_accept_transfers_in",
          "regular_contributions",
          "protect_retirement_age",
          "guaranteed_income",
          "withdrawels_from_this_pension",
          "pension_earmarking",
          "policy_permits_full_transfer_out",
          "tax_free_cash_greater_than_25_perc",
          "singular_contributions",
          "crystallised_pension",
          "loyalty_bonus",
          "take_cash_in_chunks",
          "death_benefits",
          "with_profits_fund",
          "mix_your_options",
          "fees_charges_penalties",
          "waiver_of_premium",
          "contributions_currently_being_made",
          "employer_contribution",
          "take_your_whole_pot",
      ].includes(this.ePS) ) {
        op=op.filter(i=>i.id!=='<'&&i.id!=='>');
      }
      return op;
    }
  },
  methods: {
    ...mapMutations([
        'setMlRuleType'
    ]),
    ruleSelected(value) {
      this.ruleType = value;
      if(this.ind === 1 && value !== 'entity') {
        this.setMlRuleType(this.ruleType);
      }
      this.showOptions = value !== "";
      let ruleType = value;
      // this.emitUpdate(ruleType);
      if(ruleType) {
        this.$emit('ruleSelected', {
          ind: this.ind,
          ruleType
        });
      }
    },
    // operatorsArray() {
    //   return [
    //     {
    //       id: '>',
    //       text: 'more than',
    //     },{
    //       id: '<',
    //       text: 'less then',
    //     },{
    //       id: '===',
    //       text: 'equal to',
    //     },{
    //       id: '!==',
    //       text: 'not equal to',
    //     },
    //   ];
    // },
    handleSelectedEntity(value) {
      this.ePS = value;
      this.emitUpdate(this.ePSComp);
    },
    handleSelectedProvider(value) {
      let pTemp = this.getProviders();
      for(let i in pTemp) {
        if(pTemp[i].id == value) {
          this.ePS = pTemp[i].name;
          break;
        }
      }
      this.emitUpdate(this.ePSComp);
    },
    handleSelectedOperator(value) {
      let opText = this.operatorsArray().filter(op=>op.id === value);
      if(!opText[0]) return;
      let operator = this.operator = opText[0].text;
      this.$emit('ruleSelected', {
        ind: this.ind,
        operator
      });
    },
    handleSelectedCondition(value) {
      let condition = this.condition = value;
      this.$emit('ruleSelected', {
        ind: this.ind,
        condition
      });
    },
    emitUpdate(value) {
      if(value) {
        this.$emit('ruleSelected', {
          ind: this.ind,
          ePS: value
        });
      }
    }
  }
};
</script>
<style lang="scss">
.code-block {
  padding: $spacingBase;
  border: 1px solid $gray-300;
  background-color: $gray-100;
  border-radius: 5px;
}
</style>
