<template>
  <div :class="['code-block pointer mb-4', hideStyle]" @click="handleHide">
    <div class="code-subtext" v-if="review">
      <div class="d-flex justify-content-between">
        <div><strong>Id:</strong> <span class="text-color-darkgrey">{{id}}</span> | <strong>Rule:</strong> <span class="text-color-orange">{{target}}</span> <span class="text-color-darkgrey">=</span> <span class="text-color-blue">{{resultComp}}</span></div>
        <div class="text-size-14 text-color-darkgrey created-date"><strong>Created at:</strong> {{ formatReadDateTime(created) }}</div>
      </div>
    </div>
    <div class="code-inner">
      <div class="float-right text-size-14" v-if="review"><strong>Created at:</strong> {{ formatReadDateTime(created) }}</div>
      <div v-for="(c,i) in evaluation" :key="i">
        <span class="text-size-20 text-color-darkgrey"><i>{{ i === 0 ? 'if' : 'and'}}</i></span><br>
        <span v-if="getEps(c)" class="text-color-orange text-bold text-size-20">{{getRuleType(c) !== 'any-entity' && getRuleType(c) !== 'customer-age' ? getRuleType(c) + ': ' + getEps(c) : getEps(c)}}</span> <span v-if="c?.operator" class="text-color-blue text-bold text-size-20">{{operatorText(c?.operator)}}</span> <span v-if="c?.condition" class="text-color-green text-bold text-size-20">{{getMLFieldType(getEps(c)) == 'Number' ? '£':''}}{{conditionComp(getEps(c), c?.condition)}}</span><br>
        <span v-if="(i+1) === evaluation.length" class="text-size-20 text-color-darkgrey"><i>then</i></span>
      </div>

      <span class="text-color-orange text-bold text-size-20">{{target !== null ? 'entity: ' + target : ''}}</span><br>
      <span class="text-size-20 text-color-darkgrey"><i>{{target !== null ? 'equals':'' }}</i></span><br>
      <span class="text-color-blue text-bold text-size-20">{{getMLFieldType(target) == 'Number' ? '£':''}}{{resultComp}}</span>
      <div class="text-right text-size-14 link-text text-color-red" @click="handleDelete(id)" v-if="review">delete</div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RagCodeBlock",
  props: {
    evaluation: {
      type: Array,
      default: ()=>([])
    },
    target: {
      type: String,
      default: null
    },
    result: {
      type: String,
      default: null
    },
    created: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    review: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    hide: true
  }),
  computed: {
    ...mapGetters([
      "getMLFieldType",
      "getProviders"
    ]),
    hideStyle() {
      return {
        '-hidden' : this.hide
      }
    },
    resultComp() {
      let result = this.result;
      switch(this.getMLFieldType(this.target)) {
        case 'Date':
          result = this.formatReadDate(result);
          break;
      }

      return result;
    },
  },
  methods: {
    ...mapActions([
        "deleteRagRule"
    ]),
    async handleDelete(id) {
      if(!confirm("Delete this rule?")) return;
      await this.deleteRagRule(id).catch(err=>console.log(err));
      await this.$parent.fetchData();
    },
    handleHide() {
      if(!this.review) return;
      this.hide = !this.hide;
    },
    getRuleType(c) {
      return c.ruleType || c.rule_type;
    },
    getEps(c) {

      if(c?.ePS) {
        return c?.ePS;
      }

      if(c?.entity_provider_scheme_customer) {
        let ruleType = c.ruleType || c.rule_type;
        if(ruleType === 'provider' || ruleType === 'scheme') {
          let provider = this.getProviders().filter(p=>p.id==c?.entity_provider_scheme_customer);
          return provider[0].name;
        } else {
          return c?.entity_provider_scheme_customer;
        }
      }

      return '';
    },
    operatorText(value) {
      let opText = this.operatorsArray().filter(op=>op.id === value);
      if(opText[0]) return opText[0].text;
      return value;
    },
    conditionComp(ent, val) {
      switch(this.getMLFieldType(ent)) {
        case 'Date':
          val = this.formatReadDate(val);
          break;
      }

      return val;
    },
  },
  beforeMount() {
    if(!this.review) {
      this.hide = false;
    }
  }
}
</script>
<style lang="scss">
.code {

  &-block {
    &.-hidden {
      max-height: 70px;
      overflow: hidden;

      .code-subtext {
        display: block;
      }
      .code-inner {
        opacity: 0;
      }
    }
  }
  &-subtext {
    display: none;

    .created-date {
      min-width: 300px;
      text-align: right;
    }
  }
}

</style>